const navbarAll = "#ffffff"
const darkAll = "#623249"
const secondColorAll = "#27b88b"


export const header = "#012a45";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#cf2727";
export const dimBackground = "#e6e3e3";
export const lightBackground = darkAll;
export const darkBackground = "#303030";
export const white = "#ffffff";
export const black = "#1a2131";

//theme color for Brand Invoice

export const brandName = "Designers Pro Hub";
export const brandUrl = "designersprohub.com";

export const navbar = "#511034";
export const navbarText = darkAll;
export const lightText = navbarAll;
export const mainBackgound = darkAll;
export const invoiceCard = "#e1e2e6";
export const button = "#3693ab";
export const cardBackground = "#e1e2e6";


// 002e47

